import React from 'react'
import roofBottom from "../../images/imageWithColumns/bottom.png"
import roofTop from "../../images/imageWithColumns/top.png"

const SimpleBlock = ({ data }) => {
    let { content, backgroundColor, spacing, decorationBool } = data;

    let color = "";
    switch (backgroundColor) {
        case 'GREY':
            backgroundColor = 'bg-grey';
            color = 'font-dark-blue'
            break;
        case 'BLUE':
            backgroundColor = 'bg-dark-blue';
            color = 'font-white'
            break;
        default:
            backgroundColor = 'bg-white';
            color = 'font-dark-blue'
            break;
    }

    return (
        <div className={`simple-block ${backgroundColor} ${color}${spacing ? ` spacing` : ``}`}>
            <div className="container">
                {decorationBool ? (
                    <div className="roof">
                        <img src={roofTop} alt="toit haut" className="top" />
                        <img src={roofBottom} alt="toit bas" className="bottom" />
                        <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                ) : (<div className="content" dangerouslySetInnerHTML={{ __html: content }} />)}
            </div>
        </div >
    )
}

export default SimpleBlock