import React from 'react'
import { Carousel } from 'react-bootstrap'
import AchievementBlockItemRedirection from "./AchievementBlockItemRedirection"
import { useMediaQuery } from 'react-responsive'

const SliderBlockAchievement = ({ autoScroll, achievements, media, balise }) => {
    const isMobile = useMediaQuery({ maxWidth: 768 })

    achievements = achievements.slice(0, 6)
    let elementsJSX = []
    let elements = []

    if (isMobile) {
        achievements.forEach(achievement => {
            elementsJSX.push(
                <Carousel.Item key={achievement.id}>
                    <div className="d-flex justify-content-center">
                        <AchievementBlockItemRedirection data={achievement} media={media} balise={balise} baseUrl={`/realisations/`} />
                    </div>
                </Carousel.Item>
            )
        })
    } else {
        while (achievements.length !== 0) {
            elements = achievements.slice(0, 2);
            elementsJSX.push(
                <Carousel.Item>
                    <div className="d-flex justify-content-center">
                        {elements.map(element => (
                            <AchievementBlockItemRedirection key={element.id} data={element} media={media} balise={balise} baseUrl={`/realisations/`} />
                        ))}
                    </div>
                </Carousel.Item>
            )
            for (let i = 0; i < elements.length; i++) {
                achievements.shift()
            }
        }
    }
    return (
        <div className="slider-block-achievement">
            <Carousel indicators={false} slide={autoScroll}>
                {elementsJSX}
            </Carousel>
        </div>
    )
}

export default SliderBlockAchievement