import React from "react";
import ImageWithColumnsBlock from "./ImageWithColumnsBlock"
import ListOfImagesBlock from "./ListOfImagesBlock"
import QuestionBlock from "./QuestionBlock"
import TextBlockWithImages from "./TextBlockWithImages"
import AchievementBlock from "./AchievementBlock"
import TitleBlock from "./TitleBlock"
import JumbotronBlock from "./JumbotronBlock"
import SliderBlock from "./SliderBlock";
import ComplexBlock from "./ComplexBlock";
import GalleryBlock from "./GalleryBlock";
import HomeSlider from "../../components/HomeSlider";
import SimpleBlock from "./SimpleBlock"
import MembersBlock from "../blocks/MembersBlock"

const Blocks = ({ media, page, articles, achievements, opinions }) => {
    return (
        <div id="blocks">
            {page.url === '/' && (<HomeSlider nextBlockId={page.blocks[0].id} />)}
            {page.blocks.map((block, index) => {
                switch (block.__typename) {
                    case "jhipster_SimpleBlockDTO":
                        return <SimpleBlock key={block.id} data={block} />
                    case "jhipster_ImageWithColumnsBlockDTO":
                        return <ImageWithColumnsBlock key={block.id} data={block} media={media} />
                    case "jhipster_ListOfImagesBlockDTO":
                        return <ListOfImagesBlock key={block.id} data={block} media={media} />
                    case "jhipster_QuestionBlockDTO":
                        return <QuestionBlock key={block.id} data={block} />
                    case "jhipster_TextBlockWithImagesDTO":
                        return <TextBlockWithImages key={block.id} data={block} media={media} />
                    case "jhipster_AchievementBlockDTO":
                        return <AchievementBlock key={block.id} data={block} media={media} />
                    case "jhipster_TitleBlockDTO":
                        return <TitleBlock key={block.id} data={block} media={media} />
                    case "jhipster_JumbotronBlockDTO":
                        return <JumbotronBlock key={block.id} data={block} media={media} />
                    case "jhipster_SliderBlockDTO":
                        return <SliderBlock key={block.id} data={block} media={media} articles={articles} achievements={achievements} opinions={opinions} />
                    case "jhipster_ComplexBlockDTO":
                        return <ComplexBlock key={block.id} data={block} media={media} />
                    case "jhipster_GalleryBlockDTO":
                        return <GalleryBlock key={block.id} data={block} media={media} />
                    case "jhipster_MembersBlockDTO":
                        return <MembersBlock key={block.id} data={block} media={media} />
                    default:
                        return <div key={block.id} />
                }
            })}
        </div>
    )
}

export default Blocks;