import React from "react"
import AchievementBlockItem from "./AchievementBlockItem";
import AchievementBlockItemRedirection from "./AchievementBlockItemRedirection";
import AbstractMedia from "../../components/abstractMedia/AbstractMedia";
import Title from "../../components/Title"

const AchievementBlock = (props) => {
    let { title, decorationTitle, items, backgroundColor, balise } = props.data;

    let color = "";
    switch (backgroundColor) {
        case 'GREY':
            backgroundColor = 'bg-grey';
            color = 'font-dark-blue'
            break;
        case 'BLUE':
            backgroundColor = 'bg-dark-blue';
            color = 'font-white'
            break;
        default:
            backgroundColor = 'bg-white';
            color = 'font-dark-blue'
            break;
    }

    // si c'est le bloc de réalisation on surligne le dernier mot du titre
    // quand il n'y a pas de décoration pour le titre
    let titleJSX = null;
    let index = null;
    if (!decorationTitle && title && title !== "" && title.trim().lastIndexOf(' ') !== -1) {
        title = title.trim();
        index = title.lastIndexOf(' ') + 1;
        // H3
        titleJSX = (
            <Title balise={balise} className="text-center">
                {title.substring(0, index)}
                <span className={`asap-semi-bold ${color === 'font-dark-blue' ? `underline-blue` : `underline-white`}`}>
                    {title.substring(index)}
                </span>
            </Title>
        )
    } else if (title && title !== "") {
        titleJSX = (
            <div className="roof-wrapper">
                <div className="roof">
                    {decorationTitle && (<AbstractMedia media={props.media} abstractMedia={decorationTitle} className="img-top" />)}
                    <Title balise={balise} className="title">{title}</Title>
                </div>
            </div>
        )
    } else {
        titleJSX = (null)
    }

    // order items
    items.sort((a, b) => a.ordre - b.ordre)

    let childBalise = ''
    if(balise !== null && balise !== undefined){
        childBalise = balise[0] + (parseInt(balise[1]) + 1)
    }

    return (
        <div className={`achievement-block block ${backgroundColor} ${color}`}>
            <div className="container">
                {titleJSX}
                <div className={`row`}>
                    {items.map(item => (
                        <div key={item.id} className="col-lg-4 col-sm-12">
                            {(item.url && item.url !== "") ? (
                                <AchievementBlockItemRedirection data={item} media={props.media} balise={childBalise} />
                            ) : (
                                    <AchievementBlockItem data={item} media={props.media} balise={childBalise} />
                                )}
                        </div>
                    ))}
                </div>
            </div>
        </div >
    )
}

export default AchievementBlock;