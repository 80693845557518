import React from "react"
import AbstractMedia from "../../components/abstractMedia/AbstractMedia";
import ComplexBlockItem from "./ComplexBlockItem";
import Title from "../../components/Title"

const ComplexBlock = (props) => {
    let { title, items, backgroundColor, media, titleMedia, subtitleMedia, balise } = props.data

    // order items
    items.sort((a, b) => a.ordre - b.ordre)

    let color = "";
    switch (backgroundColor) {
        case 'WHITE':
            backgroundColor = 'bg-white';
            color = 'font-dark-blue'
            break;
        case 'BLUE':
            backgroundColor = 'bg-dark-blue';
            color = 'font-white'
            break;
        default:
            backgroundColor = 'bg-grey';
            color = 'font-dark-blue'
            break;
    }

    let childBalise = ''
    if(balise !== null && balise !== undefined){
        childBalise = balise[0] + (parseInt(balise[1]) + 1)
    }

    return (
        <div className={`complex-block block ${backgroundColor} ${color}`}>
            <div className="container">
                <div className="text-center">
                    <div className="complex-block-title">
                        {(title && title !== "") && (<Title balise={balise}>{title}</Title>)}
                    </div>
                    {media && (<AbstractMedia media={props.media} abstractMedia={media} title={title} className="img-fluid" />)}
                    <div className="media-title">
                        {(titleMedia && titleMedia !== "") && (<Title balise={childBalise}>{titleMedia}</Title>)}
                    </div>
                    {(subtitleMedia && subtitleMedia !== "") && (<p>{subtitleMedia}</p>)}
                </div>
                <div className="row">
                    {items.map(item => (
                        <div key={item.id} className="col-md-6">
                            <ComplexBlockItem data={item} media={props.media} balise={childBalise} />
                        </div>
                    ))}
                </div>

            </div>

        </div>
    )
}

export default ComplexBlock