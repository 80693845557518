import React from "react"
import Title from "../../components/Title"
import AbstractMedia from "../../components/abstractMedia/AbstractMedia"
import roofBottom from "../../images/imageWithColumns/bottom.png"
import roofTop from "../../images/imageWithColumns/top.png"

const ImageWithColumnsBlock = (props) => {
    let { title, media, leftContent, rightContent, backgroundColor, decorationBool, spacing, balise } = props.data;
    let color = ""
    switch (backgroundColor) {
        case 'WHITE':
            backgroundColor = 'bg-white';
            color = 'font-dark-blue'
            break;
        case 'BLUE':
            backgroundColor = 'bg-dark-blue';
            color = 'font-white'
            break;
        default:
            backgroundColor = 'bg-grey';
            color = 'font-dark-blue'
            break;
    }

    return (
        <div className={`image-with-columns-block ${backgroundColor} ${color}`}>
            <div className="container">
                <div className="text-center">
                    <div className="image-with-columns-block-title">
                        {(title && title !== "") && (<Title balise={balise} className={((leftContent && leftContent !== "" && rightContent && rightContent !== "") || media) ? "pb-5" : null}>{title}</Title>)}
                    </div>
                    {media && (<AbstractMedia media={props.media} abstractMedia={media} title={(title && title !== "") && title} className="img-fluid" />)}
                </div>
                {(leftContent && leftContent !== "" && rightContent && rightContent !== "") && (
                    <div className={spacing ? "spacing" : null}>
                        {decorationBool ? (
                            <div className="roof">
                                <img src={roofTop} alt="toit haut" className="top" />
                                <img src={roofBottom} alt="toit bas" className="bottom" />
                                <div className="row row-roof">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="content" dangerouslySetInnerHTML={{ __html: leftContent }} />
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="content" dangerouslySetInnerHTML={{ __html: rightContent }} />
                                    </div>
                                </div>
                            </div>
                        ) : (
                                <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="content" dangerouslySetInnerHTML={{ __html: leftContent }} />
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="content" dangerouslySetInnerHTML={{ __html: rightContent }} />
                                    </div>
                                </div>
                            )}
                    </div>)}
            </div>
        </div>
    )
}

export default ImageWithColumnsBlock;