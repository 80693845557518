import React from 'react'
import { Carousel } from 'react-bootstrap'
import AbstractMedia from "../../components/abstractMedia/AbstractMedia"
import { useMediaQuery } from 'react-responsive'
import Title from "../../components/Title"

const SliderBlockArticle = ({ autoScroll, articles, media, balise }) => {
    const isMobile = useMediaQuery({ maxWidth: 768 })
    articles = articles.slice(0, 6)

    return (
        <div className="slider-block-article">
            <Carousel indicators={false} slide={autoScroll}>
                {articles.map(article => (
                    <Carousel.Item key={article.id}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 my-auto">
                                    {media && (<AbstractMedia media={media} abstractMedia={article.media} title={(article.title && article.title !== "") && article.title} className="img-fluid d-block mx-auto" />)}
                                </div>
                                <div className="col-md-6">
                                    {/* <div className="roof"> */}
                                    {/* {article.decoration && (<AbstractMedia media={media} abstractMedia={article.decoration} className="top" />)} */}
                                    <div className="article-content">
                                        <div className="date">{article.date.split("-").reverse().join("/")}</div>
                                        <div className="article-content-title">
                                            <Title balise={balise}>{article.title}</Title>
                                        </div>
                                        {!isMobile && (<div className="content" style={{ display: "flex" }} dangerouslySetInnerHTML={{ __html: article.description }} />)}
                                        <a href={`/blog/${article.url}`} className="btn-green">Lire la suite de l'article</a>
                                    </div>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    )
}

export default SliderBlockArticle