import React from 'react'
import MembersBlockItem from "./MembersBlockItem"

const MembersBlock = ({ data, media }) => {
    let { backgroundColor } = data;
    let color = ""
    switch (backgroundColor) {
        case 'WHITE':
            backgroundColor = 'bg-white';
            color = 'font-dark-blue'
            break;
        case 'BLUE':
            backgroundColor = 'bg-dark-blue';
            color = 'font-white'
            break;
        default:
            backgroundColor = 'bg-grey';
            color = 'font-dark-blue'
            break;
    }
    return (
        <div className={`members-block ${backgroundColor} ${color}`}>
            <div className="container">
                <div className="row">
                    {data.items.map(item => (
                        <div className="col-md-4">
                            <MembersBlockItem key={item.id} media={media} data={item} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default MembersBlock