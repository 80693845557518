import React from "react"
import AbstractMedia from "../../components/abstractMedia/AbstractMedia"
import Title from "../../components/Title"

const ListOfImagesBlockItem = (props) => {
    const { media, title } = props.data;
    const className = title && title.length !== 0 ? ' circle' : '';
    return (
        <div className="list-of-images-block-item text-center">
            {media && (<AbstractMedia media={props.media} abstractMedia={media} title={(title && title !== "") && title} className={`img-fluid${className}`} />)}
            {(title && title !== "") && (<Title balise={props.balise}>{title}</Title>)}
        </div>
    );
}

export default ListOfImagesBlockItem;