import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import AbstractMedia from "../../components/abstractMedia/AbstractMedia"
import zoom from "../../images/pictos/white/zoom.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { useMediaQuery } from "react-responsive"
import Title from "../../components/Title"

const AchievementBlockItem = (props) => {
    const isMobile = useMediaQuery({ maxWidth: 768 })
    const { id, title, media } = props.data;

    const [show, setShow] = useState(null);
    let handleClose = () => setShow(null);
    let handleShow = () => setShow(id);

    return (
        <>
            <div className="achievement-block-item" onClick={handleShow} aria-hidden="true">
                <div className={isMobile ? "bloc" : "bloc-zoom"}>
                    {media && (<AbstractMedia media={props.media} abstractMedia={media} title={title} className="img-fluid" />)}
                    {!isMobile && (<div className="bg"></div>)}
                    {!isMobile && (<img src={zoom} alt="zoom picto" className=" zoom" />)}
                </div>
                <Title balise={props.balise}>{title}</Title>
            </div>
            <Modal keyboard={false} show={show === id} onHide={handleClose} dialogClassName="modal-80w" centered aria-hidden="true">
                <div className="icon" onClick={handleClose} aria-hidden="true">
                    <FontAwesomeIcon icon={faTimes} size="lg" style={{ color: "#32334e" }} />
                </div>
                {media && <AbstractMedia media={props.media} abstractMedia={media} title={title} className="img-fluid" />}
            </Modal>
        </>
    )
}

export default AchievementBlockItem;