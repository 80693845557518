import React, { useState } from "react"
import AbstractMedia from "../../components/abstractMedia/AbstractMedia"
import zoom from "../../images/pictos/white/zoom.png"
import { Modal } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { useMediaQuery } from "react-responsive"

const TextBlockWithImages = (props) => {
    const isMobile = useMediaQuery({ maxWidth: 768 })
    const { id, content, decorationTop, decorationBottom, position, image1, title1, image2, title2, spacing } = props.data;
    const [show, setShow] = useState(null);
    let handleClose = () => setShow(null);

    return (
        <div className={`text-block-with-images${spacing ? ` spacing` : ``}`}>
            <div className="container">
                <div className="row">
                    <div className={`col-md-8${position === 'RIGHT' ? ` order-last` : ``}`}>
                        <div className="roof">
                            {decorationTop && (<AbstractMedia media={props.media} abstractMedia={decorationTop} className="top" />)}
                            <div className={`content${decorationTop ? ` pl-5 pt-5 pb-5` : ""}`} dangerouslySetInnerHTML={{ __html: content }} />
                            {decorationBottom && (<AbstractMedia media={props.media} abstractMedia={decorationBottom} className="bottom" />)}
                        </div>
                    </div>
                    <div className="col-md-4 my-auto">
                        <div className="text-center">
                            <div className={isMobile ? "bloc" : "bloc-zoom"} onClick={() => setShow(id + "-1")} aria-hidden="true">
                                {image1 && (<AbstractMedia media={props.media} abstractMedia={image1} title={(title1 && title1 !== "") && title1} className="img-fluid" />)}
                                {!isMobile && (<div className="bg"></div>)}
                                {!isMobile && (<img src={zoom} alt="zoom picto" className=" zoom" />)}
                            </div>
                            {(title1 && title1 !== "") && (<span className="font-green font-italic">{title1}</span>)}
                            <div className={isMobile ? "bloc" : "bloc-zoom"} onClick={() => setShow(id + "-2")} aria-hidden="true">
                                {image2 && (<AbstractMedia media={props.media} abstractMedia={image2} title={(title2 && title2 !== "") && title2} className="img-fluid" />)}
                                {!isMobile && (<div className="bg"></div>)}
                                {!isMobile && (<img src={zoom} alt="zoom picto" className=" zoom" />)}
                            </div>
                            {(title2 && title2 !== "") && (<span className="font-green font-italic">{title2}</span>)}
                        </div>
                    </div>
                </div>
            </div>
            <Modal keyboard={false} show={show === id + "-1"} onHide={handleClose} dialogClassName="modal-80w" size="lg" centered aria-hidden="true">
                <div className="icon" onClick={handleClose} aria-hidden="true">
                    <FontAwesomeIcon icon={faTimes} size="lg" style={{ color: "#32334e" }} />
                </div>
                {image1 && <AbstractMedia media={props.media} abstractMedia={image1} title={(title1 && title1 !== "") && title1} className="img-fluid" />}
            </Modal>
            <Modal keyboard={false} show={show === id + "-2"} onHide={handleClose} dialogClassName="modal-80w" centered aria-hidden="true">
                <div className="icon" onClick={handleClose} aria-hidden="true">
                    <FontAwesomeIcon icon={faTimes} size="lg" style={{ color: "#32334e" }} />
                </div>
                {image2 && <AbstractMedia media={props.media} abstractMedia={image2} title={(title2 && title2 !== "") && title2} className="img-fluid" />}
            </Modal>
        </div>
    )
}

export default TextBlockWithImages;