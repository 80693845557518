import React from "react"

const Button = ({ color, title }) => {
    return (
        <a href="/contact" className={`text-center custom-button${color ? ` bg-${color} font-white` : ``}`}>
            {title}
        </a>
    )
}

export default Button;