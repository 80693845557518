import React from "react"
import AbstractMedia from "../../components/abstractMedia/AbstractMedia";
import Title from "../../components/Title"

const ComplexBlockItem = (props) => {
    const { media, title, content, isMediaOnTop } = props.data;

    const contentJSX = media ? (
        <div className="row">
            <div className="col-md-4">
                {media && (<AbstractMedia media={props.media} abstractMedia={media} title={title} className="img-fluid mx-auto d-block size" />)}
            </div>
            <div className="col-md-8">
                <div className="complex-block-item-title">
                    <Title balise={props.balise}>{title}</Title>
                </div>
                <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
            </div>
        </div >
    ) : (
            <div className="d-flex flex-column">
                <div className="complex-block-item-title">
                    <Title balise={props.balise} className="number-title">{title}</Title>
                </div>
                <div className="content number-content" dangerouslySetInnerHTML={{ __html: content }} />
            </div>
        )

    return (
        <div className="complex-block-item">
            {isMediaOnTop ? (
                <div className="d-flex flex-column">
                    {media && (<AbstractMedia media={props.media} abstractMedia={media} title={title} className="img-fluid d-block" />)}
                    <div className="complex-block-item-title">
                        <Title balise={props.balise} className={media ? null : "number-item"}>{title}</Title>
                    </div>
                    <div className={`content${media ? "" : " number-item"}`} dangerouslySetInnerHTML={{ __html: content }} />
                </div>
            ) : (contentJSX)}
        </div >
    )
}

export default ComplexBlockItem