import React from 'react'
import AbstractMedia from "../../components/abstractMedia/AbstractMedia"

const MembersBlockItem = props => {
    const { media, name, job } = props.data;
    return (
        <div className="members-block-item">
            {media && (<AbstractMedia media={props.media} abstractMedia={media} className="img-fluid" />)}
            <span className="members-block-name">{name}</span>
            <span className="members-block-job">{job}</span>
        </div>
    )
}

export default MembersBlockItem;