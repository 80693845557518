import React from "react"
import SliderBlockAchievement from "./SliderBlockAchievement"
import SliderBlockArticle from "./SliderBlockArticle"
import SliderBlockOpinion from "./SliderBlockOpinion"
import Title from "../../components/Title"

const SliderBlock = (props) => {
  let { title, entity, backgroundColor, autoScroll, balise } = props.data;
  const { articles, achievements, opinions } = props;

  let color = "";
  switch (backgroundColor) {
    case 'WHITE':
      backgroundColor = 'bg-white';
      color = 'font-dark-blue'
      break;
    case 'BLUE':
      backgroundColor = 'bg-dark-blue';
      color = 'font-white'
      break;
    default:
      backgroundColor = 'bg-grey';
      color = 'font-dark-blue'
      break;
  }

  let childBalise = ''
  if(balise !== null && balise !== undefined){
      childBalise = balise[0] + (parseInt(balise[1]) + 1)
  }
  let carouselJSX = null;
  switch (entity) {
    case "REALISATION":
      carouselJSX = (<SliderBlockAchievement autoScroll={autoScroll} achievements={achievements} balise={childBalise} media={props.media} />)
      break;
    case "AVIS":
      carouselJSX = (<SliderBlockOpinion autoScroll={autoScroll} opinions={opinions} media={props.media} />)
      break;
    default:
      carouselJSX = (<SliderBlockArticle autoScroll={autoScroll} articles={articles} balise={childBalise} media={props.media} />)
      break;
  }

  return (
    <div className={`slider-block block ${backgroundColor} ${color}`}>
      <div className="container">
        <div className="slider-title">
          <Title balise={balise}>
            {title}
          </Title>
        </div>
      </div>
      {carouselJSX}
    </div>
  )
}

export default SliderBlock