import React from "react";
import Button from "../../components/Button"
import logo from "../../images/svg/picto/sans_deco/appel.svg"

const QuestionBlock = ({ data }) => (
    <div className="question-block block">
        <div className="bg-grey">
            <div className="bg-dark-blue">
                <div className="logo-block">
                    <div className="bg-white">
                        <img src={logo} alt="logo contact" className="img-fluid" />
                    </div>
                </div>
                <div className="content-block">
                    <p>{data.title}</p>
                    <Button color="green" title={data.titleButton} />
                </div>
            </div>
        </div>
    </div>
)

export default QuestionBlock;