import React from "react"
import AbstractMedia from "../../components/abstractMedia/AbstractMedia";
import Title from "../../components/Title"

const AchievementBlockItemRedirection = (props) => {
    let { title, media, url } = props.data;
    url = props.baseUrl ? props.baseUrl + url : url;
    return (
        <div className="achievement-block-item-redirection">
            <a href={url ? url : "/"}>
                {media && (<AbstractMedia media={props.media} abstractMedia={media} title={title} className="img-fluid" />)}
                <div className="title">
                    <Title balise={props.balise}>{title}</Title>
                </div>
            </a>
        </div>
    );
}

export default AchievementBlockItemRedirection