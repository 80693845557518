import React from "react"
import AbstractMedia from "../../components/abstractMedia/AbstractMedia";

const GalleryBlock = (props) => {
    const { image1, image2, image3, image4, image5, image6, image7, key1, key2, key3, key4, key5, key6, key7 } = props.data;
    return (
        <div className="gallery-block">
            <div className="container">
                <div className="row no-gutters">
                    {image1 && (<div className="col-md-12">
                        <div className="img-padding">
                            <AbstractMedia media={props.media} abstractMedia={image1} className="img-fluid" />
                            {(key1 && key1 !== "") && (<div className="text-center"><p>{key1}</p></div>)}
                        </div>
                    </div>)}
                    {image2 && (<div className="col-md-6">
                        <div className="img-padding">
                            <AbstractMedia media={props.media} abstractMedia={image2} className="img-fluid" />
                            {(key2 && key2 !== "") && (<div className="text-center"><p>{key2}</p></div>)}
                        </div>
                    </div>)}
                    {(image3 && image4) && (<div className="col-md-6">
                        <div className="img-padding-col">
                            <AbstractMedia media={props.media} abstractMedia={image3} className="img-fluid" />
                            {(key3 && key3 !== "") && (<div className="text-center"><p>{key3}</p></div>)}
                        </div>
                        <div className="img-padding-col">
                            <AbstractMedia media={props.media} abstractMedia={image4} className="img-fluid" />
                            {(key4 && key4 !== "") && (<div className="text-center"><p>{key4}</p></div>)}
                        </div>
                    </div>)}
                    {image5 && (<div className="col-md-12">
                        <div className="img-padding">
                            {image5 && (<AbstractMedia media={props.media} abstractMedia={image5} className="img-fluid" />)}
                            {(key5 && key5 !== "") && (<div className="text-center"><p>{key5}</p></div>)}
                        </div>
                    </div>)}
                    {image6 && (<div className="col-md-6">
                        <div className="img-padding">
                            {image6 && (<AbstractMedia media={props.media} abstractMedia={image6} className="img-fluid" />)}
                            {(key6 && key6 !== "") && (<div className="text-center"><p>{key6}</p></div>)}
                        </div>
                    </div>)}
                    {image7 && (<div className="col-md-6">
                        <div className="img-padding">
                            {image7 && (<AbstractMedia media={props.media} abstractMedia={image7} className="img-fluid" />)}
                            {(key7 && key7 !== "") && (<div className="text-center"><p>{key7}</p></div>)}
                        </div>
                    </div>)}
                </div>
            </div>
        </div>
    )
}

export default GalleryBlock