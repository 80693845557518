import React from "react"
import ListOfImagesBlockItem from "./ListOfImagesBlockItem";
import Title from "../../components/Title"

const ListOfImagesBlock = (props) => {
    let { title, items, backgroundColor, maxItems, balise, spacing } = props.data;

    // order items
    items.sort((a, b) => a.ordre - b.ordre)

    let color = ""
    switch (backgroundColor) {
        case 'WHITE':
            backgroundColor = 'bg-white';
            color = 'font-dark-blue'
            break;
        case 'BLUE':
            backgroundColor = 'bg-dark-blue';
            color = 'font-white'
            break;
        default:
            backgroundColor = 'bg-grey';
            color = 'font-dark-blue'
            break;
    }

    // items number by line
    maxItems = maxItems ? maxItems : 3;
    switch (maxItems) {
        case 4:
            maxItems = 'col-lg-3';
            break;
        case 5:
            maxItems = 'col-lg-2';
            break;
        default:
            maxItems = 'col-lg-4'
            break;
    }

    let childBalise = ''
    if(balise !== null && balise !== undefined){
        childBalise = balise[0] + (parseInt(balise[1]) + 1)
    }

    return (
        <div className={`list-of-images-block ${color} ${backgroundColor}${spacing ? ` spacing` : ``}`}>
            <div className="container">
                <div className="text-center">
                    {(title && title !== "") && (<Title balise={balise}>{title}</Title>)}
                    <div className="row justify-content-between justify-content-md-center">
                        {items.map((item, index) => (
                            <div key={item.id} className={`${maxItems} col-md-4 my-auto py-5 ${index === 0 ? `col-md-offset-1` : ``}`}>
                                <ListOfImagesBlockItem data={item} media={props.media} balise={childBalise} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListOfImagesBlock;