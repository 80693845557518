import React from "react"
import Title from "../../components/Title"
import AbstractMedia from "../../components/abstractMedia/AbstractMedia"

const JumbotronBlock = (props) => {
    let { title, content, media, format, backgroundColor, balise } = props.data;
    let imgCol = ""
    let contentCol = ""

    switch (format) {
        case "MOITIE":
            imgCol = "col-md-6"
            contentCol = "col-md-6"
            break;
        case "TIER":
            imgCol = "col-md-4"
            contentCol = "col-md-8"
            break;
        default:
            imgCol = "col-md-3"
            contentCol = "col-md-9"
            break;
    }
    let color = ""
    switch (backgroundColor) {
        case 'WHITE':
            backgroundColor = 'bg-white';
            color = 'font-dark-blue'
            break;
        case 'BLUE':
            backgroundColor = 'bg-dark-blue';
            color = 'font-white'
            break;
        default:
            backgroundColor = 'bg-grey';
            color = 'font-dark-blue'
            break;
    }
    return (
        <div className={`jumbotron-block block ${backgroundColor} ${color}`}>
            <div className="container">
                <div className="row">
                    <div className={`${imgCol} my-auto`}>
                        {media && (<AbstractMedia media={props.media} abstractMedia={media} title={(title && title !== "") && title} className="img-fluid mx-auto d-block" />)}
                    </div>
                    <div className={`${contentCol} my-auto`}>
                        <div className="jumbotron-block-title">
                            {(title && title !== "") && (<Title balise={balise} className="font-weight-bold">{title}</Title>)}
                        </div>
                        <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JumbotronBlock;