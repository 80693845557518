import React from "react"
import { navigate } from "gatsby"
import { Carousel } from "react-bootstrap"

import slider1 from "../images/homeSlider/slider-01-tradi.jpg"
import slider2 from "../images/homeSlider/slider-02-indus.jpg"
import slider3 from "../images/homeSlider/slider-03-terrasse.jpg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const HomeSlider = ({ nextBlockId }) => {
  const goDown = () => navigate(`/#title-${nextBlockId}`)
  return (
    <div className="home-slider">
      <Carousel
        prevIcon={<FontAwesomeIcon icon="chevron-left" color="#32334e" size="3x" />}
        nextIcon={<FontAwesomeIcon icon="chevron-right" color="#32334e" size="3x" />}
      >
        <Carousel.Item onClick={goDown}>
          <img
            src={slider1}
            alt="Toitures traditionnelles"
            className="d-block w-100"
          />
        </Carousel.Item>
        <Carousel.Item onClick={goDown}>
          <img
            src={slider2}
            alt="Toitures industrielles"
            className="d-block w-100"
          />
        </Carousel.Item>
        <Carousel.Item onClick={goDown}>
          <img
            src={slider3}
            alt="Toits et terrasses"
            className="d-block w-100"
          />
        </Carousel.Item>
      </Carousel>
    </div>
  )
}

export default HomeSlider
