import React from 'react'
import { Carousel } from 'react-bootstrap'
import AbstractMedia from "../../components/abstractMedia/AbstractMedia"
import roofBottom from "../../images/opinionSlider/bottom.png"
import roofTop from "../../images/opinionSlider/top.png"

const SliderBlockOpinion = ({ autoScroll, opinions, media }) => {

    opinions = opinions.slice(0, 6)

    return (
        <div className="slider-block-opinion">
            <Carousel indicators={false} slide={autoScroll}>
                {opinions.map(opinion => (
                    <Carousel.Item key={opinion.id}>
                        <div className="container">
                            {opinion.media && (<AbstractMedia media={media} abstractMedia={opinion.media} title={(opinion.name && opinion.name !== "") && opinion.name} className="img-fluid d-block mx-auto" />)}
                            <div className="text-center">
                                <div className="roof">
                                    <img src={roofTop} alt="toit haut" className="top" />
                                    <img src={roofBottom} alt="toit bas" className="bottom" />
                                    <div className="content" dangerouslySetInnerHTML={{ __html: opinion.description }} />
                                </div>
                                <p className="info">{opinion.name}, {opinion.location}</p>
                            </div>
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    )
}

export default SliderBlockOpinion